<template>
  <v-app>
    <v-main>
      <CertificateDownload/>
    </v-main>
  </v-app>
</template>

<script>
import CertificateDownload from './components/CertificateDownload';
import '@/assets/css/global/google_fonts/roboto.css'
import '@/assets/css/global/materialdesign/icons/materialdesignicons.min.css'

export default {
  name: 'App',

  components: {
    CertificateDownload,
  },

  data: () => ({
    //
  }),
};
</script>
