<template>
  <v-container class="content-container">
    <v-card
      class="mx-auto certificate-dialog"
      max-width="100vw"
      max-height="100vh"
      outlined
    >
      <v-card-title 
        class="headline"
      >
        Zertifikatsdownload
      </v-card-title>
      <v-text-field
        class="text-field"
        label="Benutzername"
        v-model="UserName"/>
      <v-text-field
          class="text-field"
          label="Passwort"              
          v-model="Password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @keydown.enter="Login"
          @click:append="showPassword = !showPassword"/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="Login">Zertifikat herunterladen</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-alert v-if="showError"
        type="error"
      >        
        {{this.responseText}}
      </v-alert>
      <v-alert v-if="showSuccess"
        type="success"
      >
        {{this.responseText}}
      </v-alert>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
  export default Vue.extend({
    name: 'CertificateDownload',
    data:() => ({
      UserName: '',
      Password: '',
      Token: '',
      LoginToken: '',
      showPassword: false,
      showError: false,
      showSuccess: false,
      responses: {
        0: 'Das Zertifikat wurde erfolgreich abgefragt.',
        1: 'Der Benutzer konnte nicht gefunden werden!',
        2: 'Der Link ist leider abgelaufen, es muss ein neuer erzeugt werden.',
        3: 'Der angegebene Link konnte leider nicht verifiziert werden. Versichern Sie sich, dass es sich um einen für Sie gültigen Link handelt!',
        4: 'Ein unbekannter Fehler ist aufgetreten!'
      },
      responseText: ''
    }),
    created: function () {
      this.GetTokenParameter();
    },
    methods:{
        Login () {
            axios.post(this.$config.GatewayUrl + '/api/admin/certificate/token/download', { Token: this.Token, UserName: this.UserName, Password: this.Password})
              .then(response => {
                  this.DownloadPfx(response.data);   
                  this.ShowSuccessMessage(0);       
            }).catch(error => {
              if(error.response.status == 400){
                this.ShowErrorMessage(error.response.data);
                return;
              }
              if(error.response.status == 401){
                this.ShowErrorMessage(1);
                return;
              }
              this.ShowErrorMessage(4);
            });
        },
        ShowSuccessMessage(code){
          this.responseText = this.responses[code];
          this.showError = false;
          this.showSuccess = true;
          setTimeout(() => {
            this.showSuccess = false
          }, 5000);
        },
        ShowErrorMessage(code){
          this.responseText = this.responses[code];
          
          if(this.responseText == undefined)
            this.responseText = this.responses[3];

          this.showSuccess = false;
          this.showError = true;
          setTimeout(() => {
            this.showError = false
          }, 5000);
        },
        DownloadPfx(data){
          let buffer = Buffer.from(data, "base64"); 
          let fileURL = window.URL.createObjectURL(new Blob([buffer], { type: 'application/octet-stream' }) );
          let fileLink = document.createElement('a');
        
          fileLink.href = fileURL;
          fileLink.setAttribute('download', this.UserName+'_certificate.pfx');
          document.body.appendChild(fileLink);    
          fileLink.click();
        }, 
        GetTokenParameter(){
          let query = window.location.search;
          const urlParams = new URLSearchParams(query);
          if(urlParams.has('token'))
            this.Token = urlParams.get('token');
        }
    }
})
</script>

<style scoped>
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .certificate-dialog{
    padding: 10px;
    width: 350px;    
    align-self: center;
  }

  .text-field{
      margin-left: 30px;
      margin-right: 30px;
  }
</style>