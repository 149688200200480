import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';

fetch(process.env.BASE_URL + "appsettings.json")
  .then((json) => {
    json.json().then((config) => {
       Vue.prototype.$config = config
       new Vue({
        vuetify,
        render: h => h(App),
      }).$mount('#app')
    })
})
